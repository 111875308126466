import React from "react"
import PropTypes from "prop-types"
import classNames from 'classnames';

import styles from "./SocialLinks.module.scss"

import Icon from '../Common/Icon/Icon';

const Individual = ({ type, value }) => {
  if (!value) {
    return null;
  }

  switch (type) {
    case 'email':
      return <a href={`mailto:${ value }`}><Icon className={styles.icon} icon="pr-mail"/></a>;
    case 'telephone':
      return <a href={`tel:${ value }`}><Icon className={styles.icon} icon="pr-phone"/></a>;
    case 'instagram':
        return <a target="_blank" rel="noopener noreferrer" href={`https://instagram.com/${ value }`}><Icon className={styles.icon} icon="pr-instagram"/></a>;
    case 'twitter':
      return <a target="_blank" rel="noopener noreferrer" href={`https://twitter.com/${ value }`}><Icon className={styles.icon} icon="pr-twitter"/></a>;
    case 'facebook':
      return <a target="_blank" rel="noopener noreferrer" href={`https://facebook.com/${ value }`}><Icon className={styles.icon} icon="pr-facebook"/></a>;
    case 'website':
      return <a target="_blank" rel="noopener noreferrer" href={`${ value }`}><Icon className={styles.icon} icon="pr-globe"/></a>;
    default:
      return null;
  }
}

const SocialLinks = ({ className, email, telephone, facebook, instagram, website, twitter }) => {
  const cls = classNames(
    styles.list,
    className
  );

  return (
    <div className={ cls }>
      <Individual type='email' value={ email } />
      <Individual type='telephone' value={ telephone } />
      <Individual type='website' value={ website } />
      <Individual type='instagram' value={ instagram } />
      <Individual type='twitter' value={ twitter } />
      <Individual type='facebook' value={ facebook } />
    </div>
  )
};

SocialLinks.propTypes = {
  email: PropTypes.string,
  website: PropTypes.string,
  instagram: PropTypes.string,
  twitter: PropTypes.string,
  facebook: PropTypes.string,
  telephone: PropTypes.string,
  className: PropTypes.string,
}

export default SocialLinks
