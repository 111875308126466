import React from "react"
// import PropTypes from "prop-types"

import Image from 'gatsby-image';
import SocialLinks from "../Common/SocialLinks";
import NumberCircle from "../Common/NumberCircle";
import Price from "../Common/Price";
import Credits from "../Layout/Credits";

import styles from "./Profile.module.scss"

const bgReq = require.context('!!file-loader!../../images/numbers', false, /\.svg/);
const foodReq = require.context('!!file-loader!../../images/food', false, /\.svg/);

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const Profile = ({ name, type, price, address, description, coords, contact, image, recommended, opening, tags, number }) => {
  const { website, telephone, instagram, twitter, facebook } = contact;

  const day = new Date().getDay();
  const getTimes = () => {
    const curr = opening[days[day].toLowerCase()];

    if (!curr) {
      return '';
    }

    if (curr[0].toLowerCase() === 'closed') {
      return <span>Closed</span>
    }

    let times = [];
    for (let i = 0; i < curr.length; i += 2) {
      times.push(`${ curr[i] } - ${ curr[i + 1] }`);
    }

    return times.map(t => <span key={t}>{ t }</span>);
  }

  return (
    <section className={ styles.profile }>
      <header className={ styles.header }>
        <SocialLinks
          className={styles.links}
          website={website}
          telephone={telephone}/>

        <h1>{ name.split(' ').map((s, i) => <span key={i}>{ s }</span>) }</h1>

        <div className={styles.number}>
          <NumberCircle className={styles.circle} number={number}/>
        </div>
      </header>

      <div className={styles.mainImageWrapper}>
        <Image className={ styles.mainImage } fluid={ image.childImageSharp.fluid }/>
      </div>

      <div className={styles.meta}>
        <div className={ styles.times }>
          <span className={styles.timesDay}>{ days[day] }</span>
          <span className={styles.timesNumbers}>{ getTimes() }</span>
        </div>

        <div className={styles.price}>
          <span className={styles.priceType}>{ type }</span>
          <Price className={styles.priceNumbers} price={price}/>
        </div>
      </div>

      <div className={styles.description}>
        <div className={styles.descriptionContainer} style={{ '--bg-image': `url(${bgReq(`./${number}.svg`)}) `}}>
          <p>{ description }</p>

          { recommended ? (
            <p>
              Try the { recommended.title.toLowerCase() } { recommended.subtitle ? recommended.subtitle.toLowerCase() : '' }
            </p>
          ) : null }
        </div>
      </div>

      <div className={styles.info}>
        <SocialLinks
          className={styles.infoLinks}
          twitter={twitter}
          instagram={instagram}
          facebook={facebook}/>

        <ul className={ styles.tags } style={{ '--bg-image': `url(${foodReq(`./${number}.svg`)}) `}}>
          { tags.slice(0, 10).map(t => <li key={t}>{ t }</li>)}
        </ul>
      </div>

      { recommended ? (
        <div className={ styles.recommended }>
          <Image fluid={ recommended.image.childImageSharp.fluid }/>
          <div className={styles.recommendedContent}>
            <div className={ styles.recommendedInfo }><span>* Try the...</span></div>
            <div className={ styles.recommendedTitle }>
              <span>{ recommended.title }</span>
              { recommended.subtitle ? <span>{ recommended.subtitle }</span> : null }
            </div>
          </div>
        </div>
      ) : null }

      <Credits className={styles.credits}/>
    </section>
  );
}

Profile.propTypes = {
}

export default Profile
