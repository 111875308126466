import React from "react"

import { graphql } from "gatsby";

import Profile from "../components/Profile/Profile";
import SEO from '../components/seo';

const ProfilePage = ({ data }) => (
  <>
    <SEO title={ data.location.name }/>
    <Profile {...data.location}/>
  </>
);

export default ProfilePage

export const query = graphql`
  query($slug: String!) {
    location: locationsYaml(fields: { slug: { eq: $slug } }) {
      address
      coords {
        lat
        lng
      }
      contact {
        email
        facebook
        instagram
        twitter
        website
        telephone
      }
      id
      description
      fields {
        slug
      }
      name
      price
      tags
      type
      recommended {
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 400, maxHeight: 250) {
              ...GatsbyImageSharpFluid
            }
          }
        },
      },
      image {
        childImageSharp {
          fluid(maxWidth: 600, maxHeight: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      opening {
        friday
        monday
        saturday
        sunday
        thursday
        tuesday
        wednesday
      },
      number
    }
  }
`;
